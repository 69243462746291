<template>
  <div class="content dis-flex4">
    <div class="content_box">
      <div class="dis-flex2 flexWrap">
        <div class="dis-flex connect_list_index dis-flex3">
          <img class="list_index_img" src="../../assets/img/icon_dizhi1.png" alt="">
           <div class="list_address color333 fz24">浙江省杭州市上城区凤起东路111号中豪五福天地11F</div>
        </div>
        <div class="dis-flex connect_list_index dis-flex3">
          <img class="list_index_img" src="../../assets/img/icon_dianhua1.png" alt="">
           <div class="list_address color333 fz24">0571-28874408 (服务时间:09:00~18:00)</div>
        </div>
      </div>
      <div class="fz20 color333 center connect-coo fw600">商务合作：182 0266 2836</div>
    </div>
  </div>
</template>

<script>
export default {
  name:"Connect"
}
</script>

<style lang="scss" scoped>
  .content{
    .content_box{
      width:1200px;
      padding:80px 0;
      padding-bottom:52px;
      .connect-coo{
        padding-top:60px;
      }
      .connect_list_index{
        padding:40px;
        box-shadow: 0px 0px 10px 1px #DDD;
        border-radius: 16px;
        .list_index_img{
          width:100px;
          height: 100px;
          margin-right:20px;
        }
        .list_address{
          width:350px;
        }
      }
    }
  }
</style>