<template>
  <div id="nav" class="pos-r">
     <!-- <Navigation :tapInfos="tapInfos" /> -->
     <!-- <Swipers></Swipers> -->
     <Bgimgs urls="https://fmcg.oss-cn-hangzhou.aliyuncs.com/pc/bg_banner_b.png"></Bgimgs>
  </div>
  <Connect></Connect>
  <!-- <Search></Search>
  <Footers></Footers> -->
  <!-- <Bottom></Bottom> -->
</template>
<script>
// @ is an alias to /src
// import Navigation from '@/components/Navigation'
import Bgimgs from '@/components/Bgimgs'
// import Swipers from '@/components/Swipers'
import Connect from '@/components/Connect'
// import Search from '@/components/Search'
// import Footers from '@/components/Footers'
// import Bottom from '@/components/Bottom'

export default {
  name: 'Home',
  components: {
    // Navigation,
    // Swipers,
    Bgimgs,
    Connect,
    // Search,
    // Footers,
    // Bottom,
  },
  setup(){
    
  },
}
</script>
<style lang="scss">

</style>
